<template>
  <button :disabled="disabled" @click="$emit('click', $event)" :class="{
  'bg-[#D6D8E7] ring-[#D6D8E7]': model,
  'bg-[#0165E1] ring-[#0165E1]': !model,
  'opacity-30': disabled
}" class="py-[1px] cursor-pointer h-[1.1rem] w-7 rounded-full ring-1 ring-inset transition duration-200 ease-in-out ">
    <div :class="model ? '' : 'translate-x-[0.73rem]'" class="h-4 w-4 rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out">
    </div>
  </button>
</template>
<script>
export default {
  name: 'SwitchButton',
  props: {
    status: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    model: {
      get() {
        return this.status
      },
      set(value) {
        this.$emit('input', this.status)
      },
    },
  }
}
</script>
